import React, { Fragment } from "react";

const CollectionStats = (props) => {
  const { skip, limit, productsNum } = props;

  let from;
  skip === 0 ? (from = 1) : (from = skip);

  let to;
  skip + limit > productsNum ? (to = productsNum) : (to = skip + limit);

  return (
    <Fragment>
      Mostrando {from} - {to} de {productsNum} resultados
    </Fragment>
  );
};

export default CollectionStats;
